import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from 'reactstrap';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Spinner } from '../../../../../components/Spinner';
import VirtualizedTable from '../../../../../components/VirtualizedTable';
import NewObjectPopoverNotificationForm from '../forms/NewObjectPopoverNotificationForm';
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { getTextWidthInOneLine } from '../../../../../utils/strings';
import IframeFullHeight from '../../../../../components/IframeFullHeight';
import TableMultiLineText from '../../../../../components/TableMultiLineText';

function NewObjectNotificationsTab({ consumerId, refreshTab, setRefreshTab }) {
  const { apiOriginNotifications } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const tableColumns = useMemo(
    () => [
      {
        key: 'timestamp',
        header: 'Send Time',
        width: 160,
        renderValue: value =>
          value ? moment.utc(value).local().format('MMM DD, YYYY H:mm:ss') : '---',
      },
      {
        key: 'type',
        header: 'Type',
        width: 180,
        renderValue: (value, row) => (
          <div className="text-truncate py-2" id={`type_${row.id}`}>
            {value}
            {getTextWidthInOneLine(value) > 150 && (
              <UncontrolledTooltip
                placement="top"
                target={`type_${row.id}`}
                innerClassName="bo-max-w-400"
                delay={{ show: 200 }}
              >
                {value}
              </UncontrolledTooltip>
            )}
          </div>
        ),
      },
      {
        key: 'title',
        header: 'Title',
        width: 250,
      },
      {
        key: 'message',
        header: 'Message',
        width: 250,
        renderValue: (value, row) => (
          <TableMultiLineText value={value} uniqueId={`message_${row.id}`} lineClamp={3} />
        ),
      },
      {
        key: 'isRead',
        header: '',
        width: 80,
        renderValue: value =>
          value ? (
            <Badge className="ms-2 bo-inline-badge" color="success">
              Read
            </Badge>
          ) : null,
      },
      {
        key: 'isDeleted',
        header: '',
        width: 100,
        renderValue: value =>
          value ? (
            <Badge className="ms-2 bo-inline-badge" color="warning">
              Deleted
            </Badge>
          ) : null,
      },
    ],
    [],
  );

  const previewColumn = useMemo(
    () => ({
      key: 'bodyType',
      header: 'Body Preview',
      width: 400,
      renderValue: (value, row) =>
        value === 'TEXT' ? (
          <div className="bo-w-400 p-2">
            <div>{row.body}</div>
          </div>
        ) : (
          <IframeFullHeight title="Body Preview" htmlBody={row.htmlBody} width={400} />
        ),
    }),
    [],
  );

  const getRowHeight = useCallback(
    index => {
      const titleWidth = getTextWidthInOneLine(notifications[index].title);
      const titleHeight = titleWidth > 230 ? Math.ceil((titleWidth / 250) * 20) + 36 : 36;

      const messageWidth = getTextWidthInOneLine(notifications[index].message);
      const messageHeight = messageWidth > 230 ? Math.ceil((messageWidth / 250) * 20) + 36 : 36;

      return Math.max(titleHeight, messageHeight > 66 ? 66 : messageHeight, 36);
    },
    [notifications],
  );

  const getNotifications = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);

    const config = {
      token,
      url: `${apiOriginNotifications}/get_notifications`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const response = await makeRequest(config);

      setNotifications(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  }, [apiOriginNotifications, consumerId, getAccessTokenSilently]);

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerId]);

  useEffect(() => {
    if (refreshTab === 'notifications') {
      getNotifications();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  const sendNotification = async type => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginNotifications}/send_predefined_message`,
      method: 'POST',
      data: JSON.stringify({ consumerId, type }),
    };

    try {
      await makeRequest(config);

      getNotifications();

      toast.success(`${type} notification has been sent`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const handlePopoverToggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div className="h-100 w-100">
      <div className="d-flex">
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen(!dropdownOpen)}
          className="m-3"
          disabled={spinner}
        >
          <DropdownToggle className="bo-new-object-dropdown-toggle" caret>
            Send Notification
          </DropdownToggle>
          <DropdownMenu className="bo-new-object-dropdown-menu">
            <DropdownItem
              className="bo-new-object-dropdown-menu-item"
              onClick={handlePopoverToggle}
            >
              Free text
            </DropdownItem>
            <DropdownItem
              className="bo-new-object-dropdown-menu-item"
              onClick={() => sendNotification('ACTIVATION')}
            >
              Activation (Pending to Active)
            </DropdownItem>
            <DropdownItem
              className="bo-new-object-dropdown-menu-item"
              onClick={() => sendNotification('ACTIVE_TO_MONITOR')}
            >
              Monitoring (Active to Monitor)
            </DropdownItem>
            <DropdownItem
              className="bo-new-object-dropdown-menu-item"
              onClick={() => sendNotification('SUSPENSION')}
            >
              Suspension
            </DropdownItem>
            <DropdownItem
              className="bo-new-object-dropdown-menu-item"
              onClick={() => sendNotification('TERMINATION')}
            >
              Termination
            </DropdownItem>
            <DropdownItem
              className="bo-new-object-dropdown-menu-item"
              onClick={() => sendNotification('PLAID_REFRESH_REQUIRED')}
            >
              Plaid Refresh Required
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <div id="popover_notification_form" />
      </div>
      <NewObjectPopoverNotificationForm
        popoverOpen={popoverOpen}
        handleToggle={handlePopoverToggle}
        consumerId={consumerId}
        getNotifications={getNotifications}
      />
      <AutoSizer>
        {({ height, width }) => (
          <Spinner visible={spinner} overlayWidth={width}>
            <VirtualizedTable
              tableRows={notifications}
              tableColumns={tableColumns}
              width={width}
              height={height - 58}
              rowKey="id"
              longMultilineColumnKey="message"
              previewColumn={previewColumn}
              getRowHeight={getRowHeight}
              headerTopBorder
            />
          </Spinner>
        )}
      </AutoSizer>
    </div>
  );
}

export default memo(NewObjectNotificationsTab);
