import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Spinner } from '../../../../../components/Spinner';
import VirtualizedTable from '../../../../../components/VirtualizedTable';
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { Badge, Button, Input } from 'reactstrap';
import {
  REWARDS_STATUS_BADGE,
  REWARDS_SURVEY_STATUS_BADGE,
} from '../../../../../constants/consumers';

function NewObjectRewardsTab({ consumerId, refreshTab, setRefreshTab }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const getRewards = useCallback(async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get_rewards`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const response = await makeRequest(config);

      setRewards(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  }, [apiOriginConsumer, consumerId, getAccessTokenSilently]);

  useEffect(() => {
    getRewards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerId]);

  useEffect(() => {
    if (refreshTab === 'rewards') {
      getRewards();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  const rewardRequest = useCallback(
    async (type, rewardId) => {
      if (window.confirm('Are you sure?')) {
        setSpinner(true);
        const token = await getAccessTokenSilently();

        if (!token) {
          return;
        }

        let url = '';

        let data = {};

        if (type === 'delete') {
          url = `${apiOriginConsumer}/rewards/test/delete`;
          data = JSON.stringify({ rewardId });
        }

        if (type === 'deleteAll') {
          url = `${apiOriginConsumer}/rewards/test/delete_all`;
          data = JSON.stringify({ consumerId });
        }

        if (type === 'processAll') {
          url = `${apiOriginConsumer}/rewards/process`;
          data = JSON.stringify({ consumerId });
        }

        try {
          await makeRequest({ token, method: 'POST', url, data });
          await getRewards();
        } catch (error) {
          toast.error(error);
        } finally {
          setSpinner(false);
        }
      }
    },
    [getRewards, getAccessTokenSilently, apiOriginConsumer, consumerId],
  );

  const tableColumns = useMemo(
    () => [
      {
        key: 'category',
        header: 'Category',
        width: 120,
      },
      {
        key: 'name',
        header: 'Name',
        width: 320,
      },
      {
        key: 'status',
        header: 'Status',
        width: 120,
        renderValue: (value, row) => {
          const badge =
            row.category === 'SURVEY' ? REWARDS_SURVEY_STATUS_BADGE : REWARDS_STATUS_BADGE;

          return (
            <Badge className="bo-inline-badge bo-w-100" color={badge[value]?.color}>
              {badge[value]?.text}
            </Badge>
          );
        },
      },
      {
        key: 'bonus',
        header: 'BridgePoints™ Grant',
        width: 240,
        renderValue: (value, row) => {
          if (row.status === 'OFFERED' || row.status === 'IN_PROGRESS') {
            return `${value.toLocaleString('en-US')} (pending)`;
          }

          if (row.status === 'SUCCEEDED') {
            return `${value.toLocaleString('en-US')} (granted on ${moment(
              row.bonusTimestemp,
            ).format('MMM DD, YYYY')})`;
          }

          return null;
        },
      },
      {
        key: 'subscriptionTimestamp',
        header: 'Days left',
        width: 100,
        renderValue: (value, row) => {
          if (row.status === 'IN_PROGRESS') {
            return `${row.data.daysToComplete - moment.utc().diff(value, 'days')}`;
          }

          return null;
        },
      },
      {
        key: 'results',
        header: 'Results',
        width: 200,
      },
      {
        key: 'subscriptionTimestamp',
        header: 'Subscribed at',
        width: 120,
        renderValue: (value, row) => {
          if (row.status !== 'OFFERED') {
            return moment(value).format('MMM DD, YYYY');
          }

          return null;
        },
      },
      {
        key: 'id',
        header: '',
        width: 100,
        renderValue: value => (
          <Button
            className="bo-new-object-inline-button"
            onClick={() => rewardRequest('delete', value)}
          >
            Delete
          </Button>
        ),
      },
    ],
    [rewardRequest],
  );

  const filteredRewards = useMemo(
    () =>
      rewards.filter(trial => {
        if (selectedCategory) {
          return trial.category === selectedCategory;
        }

        return true;
      }),
    [rewards, selectedCategory],
  );

  return (
    <div className="h-100 w-100">
      <div className="m-3 d-flex flex-row gap-3">
        <Button
          className="bo-new-object-button"
          disabled={spinner}
          onClick={() => rewardRequest('deleteAll')}
        >
          Delete All
        </Button>
        <Button
          className="bo-new-object-button"
          disabled={spinner}
          onClick={() => rewardRequest('processAll')}
        >
          Process All
        </Button>
        <Input
          type="select"
          className="bo-new-object-popover-form-menu-input bo-w-250"
          value={selectedCategory}
          onChange={e => setSelectedCategory(e.target.value)}
          disabled={spinner}
        >
          <option value="">All Categories</option>
          {[...new Set(rewards.map(trial => trial.category))].map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </Input>
      </div>
      <AutoSizer>
        {({ height, width }) => (
          <Spinner visible={spinner} overlayWidth={width}>
            <VirtualizedTable
              tableRows={filteredRewards}
              tableColumns={tableColumns}
              width={width}
              height={height - 62}
              rowKey="id"
              headerTopBorder
            />
          </Spinner>
        )}
      </AutoSizer>
    </div>
  );
}

export default memo(NewObjectRewardsTab);
