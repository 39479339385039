import * as ExcelJS from 'exceljs';
import { useCallback, useMemo } from 'react';
import { applyExcelFormats, exportExcelFile } from '../../../../utils/excel';
import ReportTable from '../ReportTable';
import ReportActionRow from '../ReportActionRow';
import moment from 'moment';

function ReportRewards({
  reportTitle,
  reportDetails,
  reportId,
  reportFileName,
  setSpinner,
  report,
}) {
  const tableColumns = useMemo(
    () => ({
      aggregatorName: {
        header: 'Aggregator Name',
        width: 23,
        style: { alignment: { horizontal: 'left' } },
      },
      consumerId: {
        header: 'Consumer ID',
        width: 16,
        style: { alignment: { horizontal: 'right' } },
      },
      firstName: {
        header: 'First Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      lastName: {
        header: 'Last Name',
        width: 16,
        style: { alignment: { horizontal: 'left' } },
      },
      category: {
        header: 'Category',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      name: {
        header: 'Reward Name',
        width: 50,
        style: { alignment: { horizontal: 'left' } },
      },
      status: {
        header: 'Status',
        width: 20,
        style: { alignment: { horizontal: 'left' } },
      },
      offerDate: {
        header: 'Offer Date',
        width: 22,
        style: { alignment: { horizontal: 'left' } },
      },
      subscriptionDate: {
        header: 'Subscription Date',
        width: 22,
        style: { alignment: { horizontal: 'left' } },
      },
      closeDate: {
        header: 'Close Date',
        width: 22,
        style: { alignment: { horizontal: 'left' } },
      },
    }),
    [],
  );

  const exportFile = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Rewards', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });

    const wsColumns = Object.entries(tableColumns).map(([key, value]) => ({ ...value, key }));

    worksheet.columns = wsColumns;

    const dateFormattedReport = report.map(r => ({
      ...r,
      offerDate: r.offerDate ? moment(r.offerDate).format('MMM DD, YYYY') : null,
      subscriptionDate: r.subscriptionDate
        ? moment(r.subscriptionDate).format('MMM DD, YYYY')
        : null,
      closeDate: r.closeDate ? moment(r.closeDate).format('MMM DD, YYYY') : null,
    }));

    worksheet.addRows(dateFormattedReport);

    const headerRow = worksheet.getRow(1);

    headerRow.alignment = { horizontal: 'left' };
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };

    await exportExcelFile(workbook, `${reportFileName}.xlsx`);
  }, [report, reportFileName, tableColumns]);

  const formattedReport = useMemo(() => {
    if (report.length > 0) {
      return report.map(r =>
        Object.entries(r).reduce((acc, [key, value]) => {
          if (key === 'offerDate' || key === 'subscriptionDate' || key === 'closeDate') {
            return {
              ...acc,
              [key]: value ? moment(value).format('MMM DD, YYYY') : null,
            };
          }

          return {
            ...acc,
            [key]:
              value !== null ? applyExcelFormats(value, tableColumns[key]?.style?.numFmt) : null,
          };
        }, {}),
      );
    }

    return [];
  }, [report, tableColumns]);

  return (
    <>
      <ReportActionRow
        reportTitle={reportTitle}
        reportDetails={reportDetails}
        reportId={reportId}
        setSpinner={setSpinner}
        exportFile={exportFile}
      />
      <ReportTable
        report={formattedReport}
        tableColumns={tableColumns}
        headerHeight={36}
        rowKey="id"
      />
    </>
  );
}

export default ReportRewards;
